import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page-template.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About Chroma`}</h1>
    <p><em parentName="p">{`Chroma`}</em>{` is a student-run magazine that aims to engage the MIT community with the intersection between the sciences and the humanities through in-depth writing and thought-provoking art.`}</p>
    <p>{`If you’re interested in joining our staff, submitting writing or art, or otherwise getting in touch, reach out to us at `}<a parentName="p" {...{
        "href": "mailto:chroma-exec@mit.edu"
      }}>{`chroma-exec@mit.edu`}</a>{`! We’ll be having meetings every Thursday evening in IAP 2021.`}</p>
    <h2>{`Editorial Board`}</h2>
    <p><strong parentName="p">{`Editors-in-Chief`}</strong><br />{`
Jennah Haque ‘21`}<br />{`
Katherine (Kat) Huang ‘23`}</p>
    <p><strong parentName="p">{`Art & Design Editor`}</strong><br />{`
Heya Lee ‘22`}</p>
    <p><strong parentName="p">{`Editor`}</strong><br />{`
Chelsea Ajunwa ‘21`}</p>
    <h2>{`Alumni`}</h2>
    <p>{`Michelle Xu ‘20, `}<em parentName="p">{`Founding Editor-in-Chief`}</em>{`. Karina Hinojosa ‘20, `}<em parentName="p">{`Editor-in-Chief, Blog Editor`}</em>{`. Rona Wang ‘22, `}<em parentName="p">{`Web Editor`}</em>{`. Theresa Machemer ‘19, `}<em parentName="p">{`Print Editor`}</em>{`. Alap Sahoo ‘19, `}<em parentName="p">{`Crisis Editor`}</em>{`. Emily Cheng ‘20, `}<em parentName="p">{`Treasurer`}</em>{`. Vanessa (Van) Pipitone ‘21, `}<em parentName="p">{`Design Editor`}</em>{`. Elina Sendonaris ‘20, `}<em parentName="p">{`Webmaster`}</em>{`. Sarah Dohadwala ‘21, `}<em parentName="p">{`Art Editor`}</em>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      